.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.navbar {
  width: 100%;
  height: 10%;
  z-index: 1;
  position: fixed;
  top: 0;
}

.video-container {
  width: 100%;
  height: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
}

.full-height {
  height: 100vh;
}
