.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.navbar {
  width: 100%;
  height: 10%;
  z-index: 1;
  position: fixed;
  top: 0;
}

.contact-container {
  margin-top: 5%;
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
