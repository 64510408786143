.container {
  width: 100vw;
  height: 100vh;
  overflow-y:scroll;
}

.navbar {
  width: 100%;
  height: 10%;
  z-index: 1;
  position: fixed;
  top: 0;
}

.details-container {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;    
  flex-direction: column;
}

.description-container {
  height: 10%;
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
}

h1{
  text-align: center;
  margin: 1%;
}

.photos-container {
  height: 60%;
  width: 100%;
  
}
