.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.navbar {
  width: 100%;
  height: 10%;
  z-index: 1;
  position: fixed;
  top: 0;
}

.offers-list {
  height: 90%;
  margin-top: 5%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:5%;
}

@media (max-width: 1200px) {

 .offers-list{
  overflow: scroll;
   margin-top: 5%;
 }
}