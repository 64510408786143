* {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.app {
  width: 100%;
  height: 100%;
}
