.animationWrapper {
  position: relative;
  overflow: hidden;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.animationPlayer {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none; /* Wyłączenie interakcji */
  width: 100%;
  height: 100%;
  filter: grayscale(15%) brightness(60%);
  image-rendering: crisp-edges; /* Ustawienie wygładzania obrazu na minimalne */
  object-fit: cover;
}

.imageContainer {
  position: absolute;
  width: 40%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}
.centeredLogo {
  position: absolute;
  width: 80%;
  height: 50%;
  object-fit: cover;
}

@media (max-width: 1400px) {
  .imageContainer {
    width: 50%;
  }
  .animationWrapper {
    align-items: center;
  }
}
@media (max-width: 1200px) {
  .imageContainer {
    width: 60%;
  }
  .animationWrapper {
    align-items: center;
  }
}

@media (max-width: 950px) {
  .imageContainer {
    width: 75%;
  }
  .animationWrapper {
    align-items: center;
  }
}

@media (max-width: 800px) {
  .imageContainer {
    width: 80%;
  }
}


@media (max-width: 800px) {
  .imageContainer {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .imageContainer {
    width: 100%;
    height: 20%;
  }
}

@media (max-width: 600px) {
  .imageContainer {
    width: 100%;
    height: 20%;
  }
  .centeredLogo{
    width: 100%;
  }
}

/* Pozostałe style dla ukrywania kontrolek odtwarzacza */
.animationPlayer::-webkit-media-controls,
.animationPlayer::-webkit-media-controls-enclosure,
.animationPlayer::-webkit-media-controls-panel,
.animationPlayer::-webkit-media-controls-overlay-play-button {
  display: none !important; /* Ukrywanie kontrolki odtwarzacza w przeglądarkach opartych na silniku WebKit */
  pointer-events: none !important;
}

/* Blokowanie przycisków odtwarzacza w przeglądarce MI */
.animationPlayer::-webkit-media-controls-start-playback-button,
.animationPlayer::-moz-media-controls-play-button,
.animationPlayer::-ms-media-controls-start {
  display: none !important;
  -webkit-appearance: none;
}

video::-webkit-media-controls {
  display: none !important;
  pointer-events: none !important;
}

video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
  -webkit-appearance: none;
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.animationPlayer::-webkit-media-controls-timeline {
  display: none !important; /* Ukrywanie paska przesuwania momentu w przeglądarkach opartych na silniku WebKit */
}

.animationPlayer::-moz-range-progress {
  background-color: transparent; /* Ukrywanie paska przesuwania momentu w przeglądarkach opartych na silniku Gecko (np. Firefox) */
}

.animationPlayer::-moz-range-thumb {
  pointer-events: none; /* Wyłączenie interakcji z kciukiem przesuwania w przeglądarkach opartych na silniku Gecko (np. Firefox) */
}

.animationPlayer::-ms-progress-bar {
  display: none; /* Ukrywanie paska przesuwania momentu w przeglądarkach opartych na silniku Edge/IE */
}

.animationPlayer::-ms-thumb {
  pointer-events: none; /* Wyłączenie interakcji z kciukiem przesuwania w przeglądarkach opartych na silniku Edge/IE */
}

.animationPlayer::-ms-tooltip {
  display: none; /* Ukrywanie etykiety przesuwania momentu w przeglądarkach opartych na silniku Edge/IE */
}

.animationPlayer::-ms-fill-lower,
.animationPlayer::-ms-fill-upper {
  background-color: transparent; /* Ukrywanie wypełnienia paska przesuwania momentu w przeglądarkach opartych na silniku Edge/IE */
}
.animationPlayer::-webkit-media-controls-start-playback-button {
  display: none !important; /* Ukrywanie przycisku odtwarzania w przeglądarkach opartych na silniku WebKit */
  -webkit-appearance: none;
}

.animationPlayer::-moz-media-controls-play-button {
  display: none !important; /* Ukrywanie przycisku odtwarzania w przeglądarkach opartych na silniku Gecko (np. Firefox) */
}

.animationPlayer::-ms-media-controls-start {
  display: none !important; /* Ukrywanie przycisku odtwarzania w przeglądarkach opartych na silniku Edge/IE */
}

video::slotted::-webkit-media-controls-container {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-appearance: none !important;
}
