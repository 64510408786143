/* Modal.module.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  width: 25%;
  height: 40%;
  background-color: white;
  border-radius: 12px;
  padding: 1%;
}

.modal-header {
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.modal-content {
  border-radius: 4px;
  display: flex;
  height: 60%;
  width: 100%;
}
.modal-para {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.2px;
  
}
.modal-content-para {
  width: 90%;
  height: 95%;
  font-size: 18px;
  overflow: auto;
  margin-left: 5%;
  text-align: left;
}


.image-container {
  width: 30%;
  height: 100%;
}

.title-container {
  height: 100%;
  width: 60%;
  border-radius: 12px;
}

.button-container {
  height: 100%;
  width: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 12px;
}


.modal-close-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;
  font-size: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 50%;
}

@media (max-width: 1300px) {
  .modal-container {
    width: 30%;
    height: 40%;
  }
}

@media (max-width: 1200px) {
  .modal-container {
    width: 45%;
    height: 45%;
  }
  .image-container {
    width: 40%;
    height: 100%;
  }
}

@media (max-width: 700px) {
  .modal-container {
    width: 80%;
    height: 50%;
  }
  .image-container {
    width: 45%;
    height: 100%;
  }
}

@media (max-width: 550px) {
  .modal-container {
    width: 80%;
    height: 60%;
  }
}
