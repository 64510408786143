.gallery-container {
  column-count: 4;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  -webkit-column-width: 25%;
  -moz-column-width: 25%;
  column-width: 25%;
}

.gallery-offer {
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .gallery-container {
    column-count: 2;
    margin-top: 15%;
  }
}

@media (max-width: 767px) {
  .gallery-container {
    column-count: 1;
    margin-top: 25%;
  }
}
@media (max-width: 500px) {
  .gallery-container {
    margin-top: 35%;
  }
}
