.container {
  width: 100%;
  height: 100%;
}

.image-div {
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  cursor: pointer;
  margin-bottom: 10px;
  height: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
}

.modal-close-button {
  position: absolute;
  top: 40px;
  right: 40px;
  padding: 5px;
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  font-size: 20px;
}

.modal-previous-button,
.modal-next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  font-size: 24px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.modal-previous-button {
  left: 10px;
  height: 40%;
}

.modal-next-button {
  right: 10px;
  height: 40%;
}

.indicator-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.active-indicator {
  background-color: white;
}
