.container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10%;
}

.header {
  width: 100%;
  height: 5vh;
}

.logo {
  width: 20vw;
  height: 10vh;
  display: flex;
  justify-content: center;
  object-fit: cover;
}

.logo-content {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.owner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.para {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5vh;
  font-size: 18px;
}
.phone {
  width: 100%;
}
.email {
  width: 100%;
}
.linkedin {
  width: 100%;
}

@media (max-width: 1400px) {
  .logo {
    width: 25vw;
  }

  
}

@media (max-width: 1200px) {
  .logo {
    width: 30vw;
  }
  
}

@media (max-width: 900px) {
  .logo {
    width: 35vw;
  }
  
}

@media (max-width: 700px) {
  .logo {
    width: 45vw;
  }
  
  .para {
    font-size: 12px;
  }
}

@media (max-width: 550px) {
  .logo {
    width: 55vw;
  }
 
}

@media (max-width: 450px) {
  .logo {
    width: 70vw;
  }
}

  @media (max-width: 350px) {
    .logo {
      width: 90vw;
    }
 

  .para {
    letter-spacing: 0.2px;
  }
}
