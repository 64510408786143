.gallery-container {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-width: 25%;
  -moz-column-width: 25%;
  column-width: 25%;
  padding: 0 12px;
}

h1 {
  font-weight: normal;
}

.gallery-pic {
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin-bottom: 15px;
  filter: grayscale(100%);
}

.gallery-pic:hover {
  transform: scale(1.01);
  filter: none;
}
@media (max-width: 1300px) {
  .gallery-container {
    column-count: 2;
  }
  .gallery-pic {
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    cursor: pointer;
    margin-bottom: 10px;
    filter: grayscale(100%);
  }
}

@media (max-width: 700px) {
  .gallery-container {
    margin-top: 20%;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  .gallery-pic {
    filter: saturate(50%);
  }
  h1 {
    font-size: 18px;
    font-weight: normal;
  }
}
