.container{
  width: 100%;
  height: 100%;
}

.navbar {
  width: 100%;
  height: 10%;
  z-index: 1;
  position: fixed;
  top: 0;
}


.images-list {
  width: 100%;
  height: 90%;
  margin-top: 5%;
  overflow-y: scroll; /* Dodaj overflow-y: scroll, aby dodać pasek przewijania, jeśli lista obrazów jest zbyt długa */
}
