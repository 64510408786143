.single-offer-container {
  position: relative;
  width: 100%;
}

.image-container {
  width: 100%;
  transition: all 250ms ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image{
  width: 60%;
}
p{
  width: 100%;
  height: 7vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  letter-spacing: 0px;
  font-size: 15px;
}
