.navbar {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 33px 66px -26px rgba(159, 164, 231, 1);
  background-color: white;
}

.logo {
  height: 100%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}
.logo-content {
  height: 100%;
  width: 100%;
  padding: 20%;
  object-fit: cover;
}
.navbar-elements {
  height: 100%;
  width: 45%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

li {
  display: block;
  list-style-type: none;
  text-decoration: none;
}
.navbar-element {
  display: block;
  position: relative;
  color: black;
  transition: transform 0.25s ease-out, background-color 0.25s ease-out,
    color 0.25s ease-out;
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-decoration: none;
}
.navbar-open {
  display: none;
}

a {
  text-decoration: none;
  color: black;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-element::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: black;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}

.navbar-element:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
@media (max-width: 1700px) {
  .navbar-elements {
    width: 60%;
  }
  .logo {
    width: 40%;
  }
}

@media (max-width: 1400px) {
  .logo {
    width: 50%;
  }
}

@media (max-width: 1200px) {
  .logo {
    width: 80%;
  }
}

@media (max-width: 700px) {
  .navbar-elements {
    display: none;
    z-index: 2;
    opacity: 0;
    animation: fade-in 0.3s ease-in-out forwards;
    animation-delay: 0.2s; /* Dodane opóźnienie */
  }

  .logo {
    width: 90%;
  }

  .navbar-elements.active {
    height: 100vh;
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-start;
  }

  .navbar-element.active {
    width: 100%;
    height: 10%;
    text-align: center;
  }

  .navbar-open {
    display: block;
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
